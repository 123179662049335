<template>
  <PromotionInvitationEditForm />
</template>

<script>
import PromotionInvitationEditForm from "../../../gapp-components/components/forms/PromotionInvitationEditForm.vue";
export default {
  components: { PromotionInvitationEditForm },
  name: "PromotionInvitation",
  metaInfo: {
    title: "Promotion Invitation"
  },

  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: "Admin"
      },
      { text: "Promotion Invitations" }
    ]);
  }
};
</script>
