<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>{{ $i18n.translate("Promotion") }} {{ $i18n.translate("Invitation") }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip bottom v-if="showApproveBtn">
        <template v-slot:activator="{ on }">
          <v-btn fab small class="mr-2" v-on="on" @click="onApprove()" :disabled="isEditing">
            <v-icon color="green">mdi-thumb-up</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Approve") }} {{ $i18n.translate("Invitation") }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="showRejectBtn">
        <template v-slot:activator="{ on }">
          <v-btn fab small class="mr-2" v-on="on" @click="onReject()" :disabled="isEditing">
            <v-icon color="red">mdi-thumb-down</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Reject") }} {{ $i18n.translate("Invitation") }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit()" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="showEditBtn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onEdit()" v-bind="attrs" v-on="on">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="showResendBtn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small v-bind="attrs" v-on="on" @click="onResend()" :disabled="isEditing">
            <v-icon>mdi-email-arrow-right</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Resend Invitation") }}</span>
      </v-tooltip>

      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab>{{ $i18n.translate("Details") }}</v-tab>
          <v-tab>{{ $i18n.translate("who approved") }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors" />

      <AlertDisplay ref="alertRef" />

      <div class="text-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-form :value="valid" v-if="!loading" v-model="valid">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-text-field label="Invitation Key" :value="form.promotionInvitationKey" readonly></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Promotion Name" :value="form.promotion.name" readonly> </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Status" :value="form.status.name" readonly></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field label="Requester" :value="form.requesterParticipant.fullName" readonly></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Approvals Needed"
                    :value="form.promotion.promotionInvitationApprovalsNeeded"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Count Submissions" :value="form.countSubmissions" readonly></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    name="firstName"
                    label="Participant First Name"
                    id="firstName"
                    v-model="firstName"
                    :rules="rules.firstName"
                    :error-messages="$error.getValidationError(errors, 'firstName')"
                    @input="$error.clearValidationError(errors, 'firstName')"
                    :readonly="!canEdit('firstName')"
                    :class="canEdit('firstName') ? 'required' : ''"
                    :outlined="isEditing && canEdit('firstName')"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    name="lastName"
                    label="Participant Last Name"
                    id="lastName"
                    v-model="lastName"
                    :rules="rules.lastName"
                    :error-messages="$error.getValidationError(errors, 'lastName')"
                    @input="$error.clearValidationError(errors, 'lastName')"
                    :readonly="!canEdit('lastName')"
                    :class="canEdit('lastName') ? 'required' : ''"
                    :outlined="canEdit('lastName')"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <EmailField
                    label="Participant Email"
                    name="email"
                    id="email"
                    v-model="form.participant.email1"
                    :rules="rules.email"
                    :readonly="!canEdit('email')"
                    :class="canEdit('email') ? 'required' : ''"
                    :outlined="canEdit('email')"
                  />
                </v-col>
              </v-row>

              <v-row v-if="form.rejectedParticipant && form.rejectedParticipant.id">
                <v-col cols="6">
                  <v-text-field label="Rejected by" :value="form.rejectedParticipant.fullName" readonly></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Reason" :value="form.rejectedReason" readonly></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    id="limitSubmissions"
                    name="limitSubmissions"
                    type="number"
                    label="Limit Submissions"
                    v-model="form.limitSubmissions"
                    :readonly="!isEditing"
                    :outlined="isEditing"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <DateTimePickerField
                    v-model="form.effectiveDate"
                    :label="$i18n.translate('Effective Date')"
                    :readonly="!isEditing"
                    :outlined="isEditing"
                  />
                </v-col>
                <v-col cols="4">
                  <DateTimePickerField
                    v-model="form.expirationDate"
                    :label="$i18n.translate('Expiration Date')"
                    :readonly="!isEditing"
                    :outlined="isEditing"
                  />
                </v-col>
              </v-row>

              <v-row
                v-if="
                  selectedProgram.promotionInvitationCustomFields &&
                    selectedProgram.promotionInvitationCustomFields.length >= 1
                "
              >
                <v-col cols="6">
                  <!-- CC Email -->
                  <EmailField
                    v-if="
                      selectedProgram.promotionInvitationCustomFields &&
                        selectedProgram.promotionInvitationCustomFields.length >= 1
                    "
                    :label="selectedProgram.promotionInvitationCustomFields[0].name"
                    v-model="form.customFieldValue1"
                    :readonly="!isEditing"
                    :outlined="isEditing"
                  />
                </v-col>
                <v-col cols="6">
                  <!-- CC Name -->
                  <CustomField
                    v-model="form.customFieldValue2"
                    v-if="
                      selectedProgram.promotionInvitationCustomFields &&
                        selectedProgram.promotionInvitationCustomFields.length >= 2
                    "
                    :customField="selectedProgram.promotionInvitationCustomFields[1]"
                    :readonly="!isEditing"
                    :outlined="isEditing"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <v-data-table :headers="participantApprovers.headers" :items="participantApprovers.items"> </v-data-table>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-form>

      <v-dialog v-model="dialog" max-width="600px" persistent>
        <v-card>
          <v-card-title v-if="isApproving" class="headline">Approve Invitation?</v-card-title>
          <v-card-title v-if="isRejecting" class="headline">Reject Invitation?</v-card-title>
          <v-card-text>
            <v-form :value="valid" v-if="!loading" v-model="valid">
              <v-row>
                <v-col cols="12">
                  <p v-if="isApproving">
                    The Invitation request for <b> {{ form.requesterParticipant.fullName }} </b> for Participant
                    <b> {{ form.participant.fullName }}</b> will be Approved. An email will be sent.
                  </p>
                  <p v-if="isRejecting">
                    The Invitation request for <b> {{ form.requesterParticipant.fullName }} </b> for Participant
                    <b> {{ form.participant.fullName }}</b> will be Rejected.
                  </p>
                </v-col>
              </v-row>
              <v-row v-if="isRejecting">
                <v-col cols="12">
                  <v-textarea
                    label="Comments"
                    name="reason"
                    id="reason"
                    v-model="rejectionReason"
                    :rules="rules.rejectedReason"
                  ></v-textarea> </v-col
              ></v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="onClose()">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="onSubmit()" :loading="loading">{{
              $i18n.translate("Agree")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import AlertDisplay from "@/gapp-components/components/display/AlertDisplay.vue";
import EmailField from "@/gapp-components/components/fields/EmailField.vue";
import DateTimePickerField from "@/gapp-components/components/fields/DateTimePickerField.vue";
import ApiService from "../../services/api.service";
import CustomField from "../../../gapp-components/components/fields/CustomField.vue";

export default {
  name: "PromotionInvitationEditForm",

  components: { ApiError, AlertDisplay, EmailField, DateTimePickerField, CustomField },

  data() {
    return {
      loading: false,
      dialog: false,
      tab: 0,
      valid: true,

      isEditing: false,
      isApproving: false,
      isRejecting: false,

      firstName: "",
      lastName: "",
      form: {
        participant: {},
        rejectedParticipant: {},
        requesterParticipant: {},
        promotion: {},
        status: {}
      },
      rejectionReason: undefined,
      errors: {},
      rules: {
        firstName: [v => !!v || "First Name is required"],
        lastName: [v => !!v || "Last Name is required"],
        email: [v => !!v || "E-mail is required"],
        limitSubmissions: [
          v => !!v || "Limit Submission is required",
          v => v > 0 || "Limit Submission  must be positive and greater than 0"
        ],
        rejectedReason: [v => !!v || "Reason is required"]
      },
      participantApprovers: {
        headers: [
          {
            value: "participantKey",
            text: "Key",
            sortable: true
          },
          {
            value: "fullName",
            text: "Full name",
            sortable: true
          },
          {
            value: "email1",
            text: "Email",
            sortable: true
          },
          {
            value: "organizationName",
            text: "Organization Name",
            sortable: true
          },
          {
            value: "participantTypeName",
            text: "Participant Type",
            align: "end",
            sortable: true
          }
        ],
        items: []
      },
      showApproveBtn: false,
      showRejectBtn: false,
      showEditBtn: false,
      showResendBtn: false
    };
  },

  methods: {
    handleButtonVisibility() {
      this.showApproveBtn =
        !this.isEditing && this.isApprover && this.form.status.name == "PROCESSING" && !this.isApprovedByApprover;
      this.showRejectBtn =
        !this.isEditing && this.isApprover && this.form.status.name == "PROCESSING" && !this.isApprovedByApprover;
      this.showEditBtn = this.form.id && this.isRequestor && this.form.status.name != "REJECTED";
      this.showResendBtn = this.form.status && this.form.status.name == "APPROVED" && this.isRequestor;
    },

    onSubmit() {
      this.loading = true;
      this.error = {};

      if (this.isApproving) {
        this.approve();
      } else if (this.isRejecting) {
        this.reject();
      } else if (this.isEditing) {
        this.edit();
      }
    },

    onApprove() {
      this.dialog = true;
      this.isApproving = true;

      this.isRejecting = false;
      this.isEditing = false;
    },

    onReject() {
      this.dialog = true;
      this.isRejecting = true;

      this.isApproving = false;
      this.isEditing = false;
    },

    onEdit() {
      this.isEditing = !this.isEditing;

      if (!this.isEditing) {
        this.fetchData();
      }
    },

    onClose() {
      this.dialog = false;
      this.isApproving = false;
      this.isEditing = false;
      this.isRejecting = false;
    },

    approve() {
      let approveForm = {};
      if (this.selectedParticipant.participantType.participantTypeKey == "100") {
        approveForm.forceApprove = true;
      }
      ApiService.post("/api/promotionInvitations/" + this.form.id + "/approve", approveForm)
        .then(() => {
          this.$refs.alertRef.success("Invitation Approved Successfully");
          this.fetchData();
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.onClose();
        });
    },

    reject() {
      let rejectForm = {};
      rejectForm.participantRejection = { id: this.selectedParticipant.id };
      rejectForm.reason = this.rejectionReason;

      ApiService.post("/api/promotionInvitations/" + this.form.id + "/reject", rejectForm)
        .then(() => {
          this.$refs.alertRef.info("Invitation Rejected Successfully");
          this.fetchData();
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.onClose();
        });
    },

    edit() {
      let user = {};
      user.firstName = this.firstName;
      user.lastName = this.lastName;

      let participant = {};
      participant.user = user;
      participant.email1 = this.form.participant.email1;

      let postForm = {};
      postForm.participant = participant;
      postForm.limitSubmissions = this.form.limitSubmissions;
      postForm.effectiveDate = this.form.effectiveDate;
      postForm.expirationDate = this.form.expirationDate;
      postForm.customFieldValue1 = this.form.customFieldValue1;
      postForm.customFieldValue2 = this.form.customFieldValue2;

      ApiService.post("/api/promotionInvitations/" + this.form.id + "/update", postForm)
        .then(() => {
          this.$refs.alertRef.info("Invitation Updated Successfully");
          this.fetchData();
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.onClose();
        });
    },

    onResend() {
      this.loading = true;

      let postForm = {};
      ApiService.post("/api/promotionInvitations/" + this.form.id + "/resend", postForm)
        .then(() => {
          this.$refs.alertRef.success("Invitation Resend Successfully");
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchData() {
      this.loading = true;
      this.isEditing = false;
      this.form.id = 0;

      ApiService.get("/api/promotionInvitations/" + this.$route.params.id)
        .then(({ data }) => {
          this.form.id = data.id;
          this.form.promotionInvitationKey = data.promotionInvitationKey;
          this.form.status = data.status;
          this.form.rejectedReason = data.rejectedReason;
          this.form.countSubmissions = data.countSubmissions;
          this.form.limitSubmissions = data.limitSubmissions;
          this.form.effectiveDate = data.effectiveDate;
          this.form.expirationDate = data.expirationDate;
          this.form.customFieldValue1 = data.customFieldValue1;
          this.form.customFieldValue2 = data.customFieldValue2;

          Promise.all([
            ApiService.getRelatedObject("rejectedParticipant", data)
              .then(({ data }) => {
                this.form.rejectedParticipant = data;
              })
              .catch(() => {
                console.log("Rejected Participant not found");
              }),

            ApiService.getRelatedObject("promotion", data)
              .then(({ data }) => {
                this.form.promotion = data;
              })
              .catch(() => {
                console.log("Promotion not found");
              }),

            ApiService.getRelatedObject("participant", data)
              .then(({ data }) => {
                this.form.participant = data;
                ApiService.getRelatedObject("user", this.form.participant)
                  .then(({ data }) => {
                    this.firstName = data.firstName;
                    this.lastName = data.lastName;
                  })
                  .catch(() => {
                    console.log("User not found");
                  });
              })
              .catch(() => {
                console.log("Participant not found");
              }),

            ApiService.getRelatedObject("requesterParticipant", data)
              .then(({ data }) => {
                this.form.requesterParticipant = data;
              })
              .catch(() => {
                console.log("Requester not found");
              }),
            ApiService.getRelatedObject("approvalParticipants", data)
              .then(({ data }) => {
                this.participantApprovers.items = data._embedded.participants;
                this.form.participantApprovers = data._embedded.participants;
              })
              .catch(() => {
                console.log("Approvals not found");
              })
          ]).finally(() => {
            this.loading = false;
            this.handleButtonVisibility();
          });
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        });
    },

    canEdit(field) {
      let result = false;
      switch (field) {
        case "firstName":
          result = this.isRequestor && this.form.status.name == "PROCESSING";
          break;
        case "lastName":
          result = this.isRequestor && this.form.status.name == "PROCESSING";
          break;
        case "email":
          result = this.isRequestor && this.form.status.name == "PROCESSING";
          break;
        default:
          result = false;
      }

      return this.isEditing && result;
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram", "selectedParticipant"]),

    isApprover() {
      return ["100", "1100"].includes(this.selectedParticipant.participantType.participantTypeKey);
    },

    isRequestor() {
      return ["100", "1000"].includes(this.selectedParticipant.participantType.participantTypeKey);
    },

    isApprovedByApprover() {
      let found = this.participantApprovers.items.find(p => p.id == this.selectedParticipant.id);
      return found != undefined;
    }
  },

  mounted() {
    if (this.$route.params.id == 0) {
      this.isEditing = true;
    }
    this.fetchData();
  }
};
</script>
